// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// offer-section
// =========================================================================
.offer-section {
	margin: 6vh 15px;

	@include media-breakpoint-up(xl) {
		display: none;
	}

	.container {
		@include media-breakpoint-up(sm) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.container-holder {
			background: $white;
			border: 2px solid $black;
			border-radius: 10px;
			padding: 30px 0;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// wide/narrow
	.column {
		&.wide {
			@include media-breakpoint-up(xl) {
				@include make-col(7);
			}
		}

		&.narrow {
			@include media-breakpoint-up(xl) {
				@include make-col(5);
			}
		}
	}

	img {
		border-radius: 10px;
	}
}

// masonry-collection-section
// =========================================================================
.masonry-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		margin-top: 20px;

		.card {
			border: none;
			border-radius: 10px;

			.card-body {
				border: 2px solid $black;
				border-top: none;
				border-radius: 0 0 10px 10px;

				@include media-breakpoint-up(xl) {
					padding: 25px;
				}

				.card-caption {
					text-align: center;

					.card-title-link {
						margin-bottom: 15px;
					}

					.card-title {
						position: relative;
						margin: -55px 15px 0 15px;
						padding: 15px;
						background: $white;
						border: 2px solid $black;
						font-size: 16px;
					}
				}

				.card-buttons {
					.card-btn {
						display: block;
						@extend .btn;
						@extend .btn-link;
					}
				}
			}
		}
	}
}

// parks-collection-section
// =========================================================================
.parks-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		margin-top: 20px;

		&.grid {
			.grid-items {
				justify-content: flex-start;

				.item {
					.card {
						border: 2px solid $black;
						border-radius: 10px;

						.card-body {
							@include media-breakpoint-up(xl) {
								padding: 25px;
							}

							.card-caption {
								text-align: center;
							}

							.card-buttons {
								.card-btn {
									display: block;
									@extend .btn;
									@extend .btn-link;
									color: $black;
								}
							}
						}
					}

					&:nth-child(3) {
						.card {
							.card-image {
								img {
									object-fit: cover;
									height: 480px;
								}
							}

							.card-body {
								display: none !important;
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin: 6vh 0;

	// owl-carousel
	.owl-carousel {
		height: 50vh;
		min-height: 480px;
		max-height: 720px;

		.item {
			align-items: flex-end;
			height: 50vh !important; // overide
			min-height: 480px;
			max-height: 720px;
			padding: unset; // overide

			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 35%;
				max-height: 250px;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%);
			}

			.owl-container {
				position: relative;
				margin-bottom: 70px;

				.owl-caption {
					text-shadow: none;
					text-align: left;

					.owl-title {
						@include font-size($h1-font-size);
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}

		.owl-dots {
			bottom: 40px;

			.owl-dot {
				span {
					width: 30px;
					height: 6px;
					margin: 0 5px;
					background: $white;
					border: none;
					border-radius: 0;
					box-shadow: none;
				}

				&.active {
					span {
						background: $primary;
					}
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}


// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
