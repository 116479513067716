// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 540px;
	max-height: 1080px;
}

// eyecatcher
.eyecatcher {
	position: relative;

	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 35%;
				max-height: 250px;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%);
			}
		}
	}

	// wysiwyg
	.wysiwyg {
		z-index: 100;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 450px;
		background: rgba($white, 0.75);
		margin-right: 0;
		padding: 50px;
		p:last-of-type {
			margin-bottom: 0;
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}
}
