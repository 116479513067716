// body
& {
	font-size: 18px;
}

// main
.main {
	position: relative;
	padding-bottom: 180px;
	background: url('/images/pattern-bg.svg') $secondary;
	background-size: 225px;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background: linear-gradient(180deg, rgba($secondary, 0) 0%, rgba($secondary, 1) 75%);
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 50%;
		background: linear-gradient(180deg, rgba($secondary, 1) 25%, rgba($secondary, 0) 100%);
	}

	section {
		position: relative;
		z-index: 2;
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid $white;
	border-radius: 10px;
}

// default-card
.default-card {
	border: none;
	border-radius: 10px;

	.card-body {
		border: 2px solid $black;
		border-top: none;
		border-radius: 0 0 10px 10px;

		@include media-breakpoint-up(xl) {
			padding: 25px;
		}

		.card-caption {
			text-align: center;
		}

		.card-buttons {
			.card-btn {
				display: block;
			}
		}
	}
}

// info-nav
&.accommodation-category-detail {
	.info-nav {
		display: none !important;
	}
}
