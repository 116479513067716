// btn
.btn {
	padding: 14px 20px;

	@include media-breakpoint-up(xl) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	border-radius: 24px;
	font-weight: 700;
	line-height: 20px;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		&:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	color: $black;
	font-weight: 700;
	font-family: $font-family-secondary;
	text-decoration: none;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
