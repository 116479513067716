// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	display: flex;
	flex-wrap: wrap;
	width: 75%;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		margin-right: 20px;
		padding: 0 0 0 20px;
		//font-size: 16px;
		font-weight: 700;
		font-family: $font-family-secondary;

		&::before {
			content: '\f111';
			position: absolute;
			top: 4px;
			left: 0;
			font-family: $font-awesome;
			color: $black;
			font-size: 10px;
			font-weight: 900;
		}
		a {
			color: $body-color;
			&:hover {
				color: $primary;
			}
		}
	}
}
