// footer
.footer {
	padding-bottom: 30px;
	background: $white;

	// footer-outro
	.footer-outro {
		margin: 0 15px;
		.container {
			@include media-breakpoint-up(sm) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}

		.container-holder {
			margin-top: -180px;
			@extend .bg-dark;
			position: relative;
			background: $secondary;
			border: 2px solid $black;
			border-radius: 20px;
			padding: 30px 0;

			@include media-breakpoint-up(xl) {
				padding: 50px 25px;
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-top: 30px;

			.list {
				.list-item {
					margin: 15px 15px 0 0;

					.link {
						@extend .btn;
						@extend .btn-primary;
						text-decoration: none !important;
					}

					&:nth-last-child(-n+2) {
						.link {
							width: 48px;
							height: 48px;
							padding: 0;
							border-radius: 100%;
							font-size: 24px;

							i {
								line-height: 46px;
							}
						}
					}
				}
			}
		}
	}

	// footer-address
	.footer-address {
		margin: 6vh 0;

		// footer-text
		.footer-text {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					a {
						font-size: 16px;
						font-weight: 700;
						font-family: $font-family-secondary;
					}
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		margin: 10vh 0 6vh 0;

		// footer-text
		.footer-text {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				margin: 0 -15px;
				padding: 0;
				list-style: none;

				li {
					margin: 15px;
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		font-size: 14px;
		font-weight: 700;
		font-family: $font-family-secondary;

		// footer-link
		.footer-link {
			.list {
				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				.list-item {
					@include media-breakpoint-up(lg) {
						margin-right: 45px;
					}

					.link {
						color: $black;
					}
				}
			}
		}
	}
}
