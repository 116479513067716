// mini-sab
.mini-sab {

	@include media-breakpoint-down(lg) {
		background: url('/images/pattern-bg.svg') $secondary;
		background-size: 225px;
	}

	// container
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;

			// above eyecatcher
			@include media-breakpoint-up(xl) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 80px;
			}

			@include media-breakpoint-down(lg) {
				margin-top: -180px;
			}
		}
	}

	// heading
	.heading {
		margin-bottom: 30px;
		color: $white;
		@extend .h1;
	}

	// mini-search-book
	.mini-search-book {
		padding: 15px;
		border-radius: 44px;
		background: $white;

		@include media-breakpoint-down(md) {
			border-radius: 25px;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

}

// main
&.main {
	&.home {
		.mini-sab {
			.container {
				.container-holder {
					@include media-breakpoint-up(xl) {
						min-width: 760px;
						margin-right: 450px;
					}
				}
			}
		}
	}
}

// default
&.default {

	.mini-sab {
		.container {
			.container-holder {
				max-width: 900px;
			}
		}

		.heading {
			@include media-breakpoint-up(xl) {
				font-size: 60px;
			}
		}
	}
}


.w3-vue-booking-wrapper .miniwidget-input-field,
.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-autocomplete .input,
.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-checkbox .input-holder .input,
.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-daterangepicker input,
.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-radio .input-holder .input,
.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-travelgroup .input-holder .input {
	padding: 0;
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .w3-travelgroup .popover-travelgroup {
	padding-right: 15px;
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters {
	justify-content: flex-start;
}

@media(min-width: 1200px) {
	.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-date {
		max-width: 170px;
	}
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-button button {
	margin-right: 0;
	max-width: 123px;
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .filters .filter-element.filter-element-0 .w3-travelgroup {
	margin-right: 0;
}

.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters {
	justify-content: center;
}

@media(min-width: 992px) {
	.w3-filter-collection .filter-element > .w3-radio {
		border-right: 1px solid #000;
		padding-right: 15px;
		margin-right: 0;
	}
}
