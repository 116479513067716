// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$orange: #e85c0f;
$green-light: #c0c7a7;
$green-dark: #acb492;

$purple: #312783;
$green: #006633;

$white: #fff;
$black: #000;

// css3 root vars setup
:root {
	@include defineColorHSLA(--w3-primary-color, $orange);
	@include defineColorHSLA(--w3-secondary-color, $green-light);
	@include defineColorHSLA(--w3-tertiary-color, $green-dark);
	//@include defineColorHSLA(--w3-quaternary-color, $black);
}

// theme-colors
$primary: var(--w3-primary-color);
$secondary: var(--w3-secondary-color);
$tertiary: var(--w3-tertiary-color);

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Courier Prime', monospace;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;

$h1-font-size: $font-size-base * 3.125; // 50px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-line-height: 1;
$headings-color: $black;

// contextual
$light: $green-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);
$border-radius: 10px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
