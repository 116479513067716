// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	padding: 15px 0;

	@include media-breakpoint-up(xl) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&.sticky {
		box-shadow: $shadow;
	}

	.container-fluid {
		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// logos
	div.logos {
		@include media-breakpoint-down(md) {
			order: 4;
			flex-basis: 100%;
			margin-top: 15px;
		}

		margin-right: auto;

		ul {
			flex-flow: row nowrap !important;

			@include media-breakpoint-down(md) {
				justify-content: center;
			}

			li {
				+li {
					margin-left: 45px;
				}

				a {
					display: block;

					img {
						@include media-breakpoint-down(md) {
							max-height: 60px;
						}

						max-height: 95px;
					}

					span {
						display: none;
					}
				}
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(md) {
			order: 4;
			flex-basis: 100%;
			margin-top: 15px;
		}

		@include media-breakpoint-up(lg) {
			margin-right: auto;
		}

		a {
			display: block;

			img {
				@include media-breakpoint-down(md) {
					max-height: 60px;
				}

				max-height: 95px;
			}
		}
	}

	// other-logo
	.other-logo {
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 45px;
			right: 15px;
		}

		@include media-breakpoint-up(lg) {
			margin-right: 30px;
		}

		a {
			display: flex;
			align-items: center;
			color: $black;
			font-weight: 700;
			font-family: $font-family-secondary;
			text-decoration: none;
			@include media-breakpoint-down(sm) {
				width: 100px;
				font-size: 10px;
			}
			img {
				@include media-breakpoint-down(md) {
					width: 30px;
				}

				width: 40px;
				margin-right: 5px;
			}

			span {
				text-decoration: underline;
			}

			&:hover {
				color: $orange;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(md) {
			margin-right: auto;
		}

		.navbar-toggler {
			display: flex !important;
			align-items: center;
			padding: 0;
			border: none;
			color: $black;
			font-size: 20px;
			font-weight: 700;
			font-family: $font-family-secondary;
			text-transform: uppercase;
			transition: color 0.5s;

			&[aria-expanded="true"] {
				color: $primary;

				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			.navbar-toggler-icon {
				margin-right: 10px;
				font-size: 24px;
			}

			&:hover {
				color: $primary;
			}
		}
	}

	// book-button
	div.book-button {
		margin: 0 25px;

		ul {
			li {
				a {
					display: block;
					@extend .btn;
					@extend .btn-primary;
					padding: 7px 15px !important;
					font-size: 18px;
					@include media-breakpoint-down(sm) {
						padding: 0 10px !important;
						font-size: 13px;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						img {
							@include media-breakpoint-up(lg) {
								width: 28px;
								height: 28px;
							}
						}
					}
				}
			}
		}
	}

	// menu-overlay
	.menu-overlay {
		&.collapse:not(.show) {
			display: none;
		}

		.container {
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
			}
		}

		// menu
		.menu {
			display: flex;

			@include media-breakpoint-down(lg) {
				.navbar-nav {
					width: 100%;
					margin-top: 15px;

					>.nav-item {
						>.nav-link {
							color: $black;
							font-size: 20px;
							font-weight: 700;
							font-family: $font-family-secondary;
						}

						border-top: 1px solid rgba($black, 0.1);

						&:first-of-type {
							border-top: none;
						}
					}

					.nav-item {
						.nav-link {
							color: $black;

							&:hover {
								color: $primary;
							}

							&.dropdown-toggle {
								&::after {
									content: "\f107";
									position: absolute;
									top: 0;
									right: 0;
									color: $black;
									font-size: 20px;
									line-height: 40px;
									font-family: $font-awesome;
									border: none;
									margin-left: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $primary;
							}
						}

						.dropdown-menu {
							position: static !important;
							transform: none !important;
							margin: 0 0 0 20px;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;
						}
					}
				}
			}

			@include media-breakpoint-up(xl) {
				margin: 6vh 0;

				.navbar-nav {
					flex-wrap: wrap;
					flex-direction: row;

					>.nav-item {
						margin-bottom: 30px;
						padding-right: 30px;

						>.nav-link {
							color: $black;
							font-size: 20px;
							font-weight: 700;
							font-family: $font-family-secondary;

							&.dropdown-toggle {
								pointer-events: none;
							}

							&::after {
								display: none;
							}

							&:hover {
								//color: unset;
							}
						}
					}

					.nav-item {
						.nav-link {
							padding: 0;
							color: $black;

							&:hover {
								color: $primary;
							}

						}

						.dropdown-menu {
							position: static !important;
							transform: none !important;
							display: block;
							margin: 0;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;

							.nav-item {
								margin-top: 5px;

								&.active {
									.nav-link {
										color: $primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
